export const inventoryPolicy = {
  CONTINUE: 'continue',
  DENY: 'deny'
};

export const inventoryManagement = {
  TRACKING: 'shopify'
};

export const StoreFrontAPIConfig = {
  // Used by storefront-client.js to build the graphql endpoint
  VERSION: '2025-01'
};

export const productTypes = {
  YARN: 'yarn',
  HOOKS: 'crochet_hooks',
  NEEDLES: 'knitting_needles',
  ACCESSORY: 'accessory',
  BOOK: 'book',
  CROCHET: 'crochet',
  KNITTING: 'knitting'
};
